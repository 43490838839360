<template>
  <b-row class="patient-medication-card">
    <b-col v-if="medicationType" cols="2">
      <img :src="getImage" loading="lazy" />
    </b-col>
    <b-col :cols="cols[0]" class="ml-3">
      <b-row>
        <b-col cols="8">
          <p class="medicine-name">
            <small
              v-if="!medicationType"
              class="circle mt-1"
              :class="dripped ? 'dripped' : 'not-dripped'"
            />
            {{ medicine }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="ml-auto status">
            <small class="circle" :class="status" />
            {{ parseStatus }}
          </span>
        </b-col>
      </b-row>
      <b-row class="footer-card">
        <b-col :cols="cols[1]">
          <span class="info default"> <Eye class="icon" /> {{ getEye }} </span>
          <span class="info default ml-2"> {{ timesADay }}x ao dia </span>
          <span class="info light-orange ml-2">
            {{ abbreviateDisease }}
          </span>
        </b-col>

        <b-col v-if="dosage" class="stats-div">
          <b-row class="ml-auto content">
            <b-col cols="5">
              <span class="stats">
                {{ dosage }}/{{ timesADay }}
                <small
                  class="circle drop ml-1"
                  :class="dripped ? 'ENABLED' : 'not-dripped'"
                />
              </span>
              <span class="default-label">Aplicações</span>
            </b-col>

            <b-col cols="7">
              <span class="stats d-flex">
                {{ percentage }}%
                <VueSvgGauge
                  class="gauge ml-2 mb-3"
                  :start-angle="-90"
                  :end-angle="90"
                  :value="percentage"
                  :min="0"
                  :max="100"
                  :separator-step="0"
                  base-color="#D9DFF2"
                  gauge-color="#00C773"
                />
              </span>
              <span class="default-label">Sucesso no mês</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import EyeDropImage from '@/assets/images/glauco/eye-drop.png'
import OralMedicineImage from '@/assets/images/glauco/oral-medicine.png'
import InjectionMedicine from '@/assets/images/glauco/injection-medicine.png'
import LaserMedicine from '@/assets/images/glauco/laser-medicine.png'
import Eye from '@/assets/icons/eye.svg'

export default {
  name: 'PatientMedicationCard',
  components: {
    Eye
  },
  props: {
    medicine: {
      type: String,
      required: true
    },
    eyes: {
      type: String,
      required: true
    },
    timesADay: {
      type: [Number, String],
      required: true
    },
    disease: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    medicationType: {
      type: String,
      default: ''
    },
    dosage: Number,
    dripped: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getImage() {
      return (
        {
          eye_drop: EyeDropImage,
          oral: OralMedicineImage,
          injection: InjectionMedicine,
          laser: LaserMedicine
        }[this.medicationType] || EyeDropImage
      )
    },
    getEye() {
      return (
        {
          both: 'A',
          left: 'E',
          right: 'D'
        }[this.eyes] || 'A'
      )
    },
    parseStatus() {
      return (
        {
          ENABLED: 'Em uso',
          DISABLED: 'Finalizado'
        }[this.status] || 'Em uso'
      )
    },
    cols() {
      return this.medicationType ? [9, 12] : [11, 7]
    },
    abbreviateDisease() {
      if (this.disease.indexOf('DMRI') !== -1) return 'DMRI'
      return this.disease.length > 20
        ? `${this.disease.substring(0, 20)}...`
        : this.disease
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-medication-card {
  padding: 10px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  background-color: var(--neutral-000);
  max-width: 97%;
  margin-bottom: 2%;

  .medicine-name {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    &.ENABLED {
      background-color: var(--states-success);
    }

    &.DISABLED {
      background-color: var(--neutral-200);
    }

    &.dripped {
      background-color: var(--blue-500);
    }

    &.not-dripped {
      background-color: var(--orange);
    }
  }

  p {
    font-size: min(1.2vw, 16px);
    font-weight: 700;
    color: var(--type-active);
  }

  .status {
    color: var(--type-placeholder);
    font-size: min(1vw, 12px);
    font-weight: 600;
  }

  .footer-card {
    margin-top: 4%;
    margin-left: auto !important;
    .info {
      padding: 3px 10px;
      border-radius: 18px;
      width: fit-content;
      color: var(--type-active);
      font-size: 12px;
      font-weight: 700;
      margin: auto 0;
      &.default {
        background-color: var(--neutral-300);
      }

      &.light-orange {
        background-color: var(--light-orange-200);
      }
      .icon {
        width: 15px;
        height: 15px;
      }
    }

    .stats-div {
      .content {
        .stats {
          font-size: min(2.6vw, 18px);
          font-weight: 600;
          color: var(--type-active);
        }

        .default-label {
          font-size: 12px;
          font-weight: 600;
          color: var(--type-placeholder);
          line-height: 130%;
        }

        .drop {
          height: 17px;
          width: 16px;
          border-radius: 0% 120% 100% 120%;
          transform: rotate(45deg);
        }

        .gauge {
          width: 32px;
          height: 16px;
          margin-top: -4px;
        }
      }
    }
  }
}
</style>

